export const Auth = () => import('../../components/Auth.vue' /* webpackChunkName: "components/auth" */).then(c => wrapFunctional(c.default || c))
export const AuthProfile = () => import('../../components/Auth/Profile.vue' /* webpackChunkName: "components/auth-profile" */).then(c => wrapFunctional(c.default || c))
export const AuthSignin = () => import('../../components/Auth/Signin.vue' /* webpackChunkName: "components/auth-signin" */).then(c => wrapFunctional(c.default || c))
export const AuthSocial = () => import('../../components/Auth/Social.vue' /* webpackChunkName: "components/auth-social" */).then(c => wrapFunctional(c.default || c))
export const CartBar = () => import('../../components/Cart/Bar.vue' /* webpackChunkName: "components/cart-bar" */).then(c => wrapFunctional(c.default || c))
export const CartEmpty = () => import('../../components/Cart/Empty.vue' /* webpackChunkName: "components/cart-empty" */).then(c => wrapFunctional(c.default || c))
export const Cart = () => import('../../components/Cart/Index.vue' /* webpackChunkName: "components/cart" */).then(c => wrapFunctional(c.default || c))
export const CartItem = () => import('../../components/Cart/Item.vue' /* webpackChunkName: "components/cart-item" */).then(c => wrapFunctional(c.default || c))
export const CartMobile = () => import('../../components/Cart/Mobile.vue' /* webpackChunkName: "components/cart-mobile" */).then(c => wrapFunctional(c.default || c))
export const CartPromocode = () => import('../../components/Cart/Promocode.vue' /* webpackChunkName: "components/cart-promocode" */).then(c => wrapFunctional(c.default || c))
export const CartSummary = () => import('../../components/Cart/Summary.vue' /* webpackChunkName: "components/cart-summary" */).then(c => wrapFunctional(c.default || c))
export const EventCard = () => import('../../components/Event/Card.vue' /* webpackChunkName: "components/event-card" */).then(c => wrapFunctional(c.default || c))
export const EventCardImage = () => import('../../components/Event/CardImage.vue' /* webpackChunkName: "components/event-card-image" */).then(c => wrapFunctional(c.default || c))
export const EventCardSkeleton = () => import('../../components/Event/CardSkeleton.vue' /* webpackChunkName: "components/event-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const EventCompact = () => import('../../components/Event/Compact.vue' /* webpackChunkName: "components/event-compact" */).then(c => wrapFunctional(c.default || c))
export const EventGrid = () => import('../../components/Event/Grid.vue' /* webpackChunkName: "components/event-grid" */).then(c => wrapFunctional(c.default || c))
export const FiltersCategories = () => import('../../components/Filters/Categories.vue' /* webpackChunkName: "components/filters-categories" */).then(c => wrapFunctional(c.default || c))
export const FiltersCountry = () => import('../../components/Filters/Country.vue' /* webpackChunkName: "components/filters-country" */).then(c => wrapFunctional(c.default || c))
export const FiltersLocations = () => import('../../components/Filters/Locations.vue' /* webpackChunkName: "components/filters-locations" */).then(c => wrapFunctional(c.default || c))
export const Filters = () => import('../../components/Filters/index.vue' /* webpackChunkName: "components/filters" */).then(c => wrapFunctional(c.default || c))
export const FormEventSubscribe = () => import('../../components/Form/EventSubscribe.vue' /* webpackChunkName: "components/form-event-subscribe" */).then(c => wrapFunctional(c.default || c))
export const FormPrivateEvent = () => import('../../components/Form/PrivateEvent.vue' /* webpackChunkName: "components/form-private-event" */).then(c => wrapFunctional(c.default || c))
export const FormRegister = () => import('../../components/Form/Register.vue' /* webpackChunkName: "components/form-register" */).then(c => wrapFunctional(c.default || c))
export const FormReset = () => import('../../components/Form/Reset.vue' /* webpackChunkName: "components/form-reset" */).then(c => wrapFunctional(c.default || c))
export const FormRestore = () => import('../../components/Form/Restore.vue' /* webpackChunkName: "components/form-restore" */).then(c => wrapFunctional(c.default || c))
export const FormSignin = () => import('../../components/Form/Signin.vue' /* webpackChunkName: "components/form-signin" */).then(c => wrapFunctional(c.default || c))
export const HeaderLangSwitch = () => import('../../components/Header/LangSwitch.vue' /* webpackChunkName: "components/header-lang-switch" */).then(c => wrapFunctional(c.default || c))
export const HeaderLogo = () => import('../../components/Header/Logo.vue' /* webpackChunkName: "components/header-logo" */).then(c => wrapFunctional(c.default || c))
export const HeaderMobLangSwitch = () => import('../../components/Header/MobLangSwitch.vue' /* webpackChunkName: "components/header-mob-lang-switch" */).then(c => wrapFunctional(c.default || c))
export const HeaderMobileHelp = () => import('../../components/Header/MobileHelp.vue' /* webpackChunkName: "components/header-mobile-help" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigation = () => import('../../components/Header/Navigation.vue' /* webpackChunkName: "components/header-navigation" */).then(c => wrapFunctional(c.default || c))
export const HeaderSearchBar = () => import('../../components/Header/SearchBar.vue' /* webpackChunkName: "components/header-search-bar" */).then(c => wrapFunctional(c.default || c))
export const NewsWidget = () => import('../../components/News/Widget.vue' /* webpackChunkName: "components/news-widget" */).then(c => wrapFunctional(c.default || c))
export const PaymentError = () => import('../../components/Payment/Error.vue' /* webpackChunkName: "components/payment-error" */).then(c => wrapFunctional(c.default || c))
export const PaymentSuccess = () => import('../../components/Payment/Success.vue' /* webpackChunkName: "components/payment-success" */).then(c => wrapFunctional(c.default || c))
export const AtomsDropdown = () => import('../../components/atoms/Dropdown.vue' /* webpackChunkName: "components/atoms-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Timeline = () => import('../../components/Timeline/Index.vue' /* webpackChunkName: "components/timeline" */).then(c => wrapFunctional(c.default || c))
export const TimelineItem = () => import('../../components/Timeline/Item.vue' /* webpackChunkName: "components/timeline-item" */).then(c => wrapFunctional(c.default || c))
export const TimelineSkeleton = () => import('../../components/Timeline/Skeleton.vue' /* webpackChunkName: "components/timeline-skeleton" */).then(c => wrapFunctional(c.default || c))
export const CookiesConsent = () => import('../../components/cookies/Consent.vue' /* webpackChunkName: "components/cookies-consent" */).then(c => wrapFunctional(c.default || c))
export const CookiesModal = () => import('../../components/cookies/Modal.vue' /* webpackChunkName: "components/cookies-modal" */).then(c => wrapFunctional(c.default || c))
export const ElementsCartControl = () => import('../../components/elements/CartControl.vue' /* webpackChunkName: "components/elements-cart-control" */).then(c => wrapFunctional(c.default || c))
export const ElementsDropdown = () => import('../../components/elements/Dropdown.vue' /* webpackChunkName: "components/elements-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ElementsEarlyAccessPresale = () => import('../../components/elements/EarlyAccessPresale.vue' /* webpackChunkName: "components/elements-early-access-presale" */).then(c => wrapFunctional(c.default || c))
export const ElementsGooglemap = () => import('../../components/elements/Googlemap.vue' /* webpackChunkName: "components/elements-googlemap" */).then(c => wrapFunctional(c.default || c))
export const ElementsHero = () => import('../../components/elements/Hero.vue' /* webpackChunkName: "components/elements-hero" */).then(c => wrapFunctional(c.default || c))
export const ElementsLoader = () => import('../../components/elements/Loader.vue' /* webpackChunkName: "components/elements-loader" */).then(c => wrapFunctional(c.default || c))
export const ElementsModal = () => import('../../components/elements/Modal.vue' /* webpackChunkName: "components/elements-modal" */).then(c => wrapFunctional(c.default || c))
export const ElementsOverlay = () => import('../../components/elements/Overlay.vue' /* webpackChunkName: "components/elements-overlay" */).then(c => wrapFunctional(c.default || c))
export const ElementsSeatmap = () => import('../../components/elements/Seatmap.vue' /* webpackChunkName: "components/elements-seatmap" */).then(c => wrapFunctional(c.default || c))
export const ElementsSpotlight = () => import('../../components/elements/Spotlight.vue' /* webpackChunkName: "components/elements-spotlight" */).then(c => wrapFunctional(c.default || c))
export const ElementsTab = () => import('../../components/elements/Tab.vue' /* webpackChunkName: "components/elements-tab" */).then(c => wrapFunctional(c.default || c))
export const ElementsTabs = () => import('../../components/elements/Tabs.vue' /* webpackChunkName: "components/elements-tabs" */).then(c => wrapFunctional(c.default || c))
export const ElementsTicketSelector = () => import('../../components/elements/TicketSelector.vue' /* webpackChunkName: "components/elements-ticket-selector" */).then(c => wrapFunctional(c.default || c))
export const ElementsTicketsButButton = () => import('../../components/elements/TicketsButButton.vue' /* webpackChunkName: "components/elements-tickets-but-button" */).then(c => wrapFunctional(c.default || c))
export const ElementsTimer = () => import('../../components/elements/Timer.vue' /* webpackChunkName: "components/elements-timer" */).then(c => wrapFunctional(c.default || c))
export const InsuranceModal = () => import('../../components/insurance/Modal.vue' /* webpackChunkName: "components/insurance-modal" */).then(c => wrapFunctional(c.default || c))
export const LayoutAuth = () => import('../../components/layout/Auth.vue' /* webpackChunkName: "components/layout-auth" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/layout/Footer.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../components/layout/Header.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const FormProfilePasswordReset = () => import('../../components/Form/Profile/PasswordReset.vue' /* webpackChunkName: "components/form-profile-password-reset" */).then(c => wrapFunctional(c.default || c))
export const FormProfilePersonalData = () => import('../../components/Form/Profile/PersonalData.vue' /* webpackChunkName: "components/form-profile-personal-data" */).then(c => wrapFunctional(c.default || c))
export const AtomsTicketSelectorPromocode = () => import('../../components/atoms/TicketSelector/Promocode.vue' /* webpackChunkName: "components/atoms-ticket-selector-promocode" */).then(c => wrapFunctional(c.default || c))
export const AtomsTicketSelector = () => import('../../components/atoms/TicketSelector/TicketSelector.vue' /* webpackChunkName: "components/atoms-ticket-selector" */).then(c => wrapFunctional(c.default || c))
export const LayoutFormCheckbox = () => import('../../components/layout/Form/Checkbox.vue' /* webpackChunkName: "components/layout-form-checkbox" */).then(c => wrapFunctional(c.default || c))
export const LayoutFormInput = () => import('../../components/layout/Form/Input.vue' /* webpackChunkName: "components/layout-form-input" */).then(c => wrapFunctional(c.default || c))
export const LayoutFormPassword = () => import('../../components/layout/Form/Password.vue' /* webpackChunkName: "components/layout-form-password" */).then(c => wrapFunctional(c.default || c))
export const LayoutFormPhoneInput = () => import('../../components/layout/Form/PhoneInput.vue' /* webpackChunkName: "components/layout-form-phone-input" */).then(c => wrapFunctional(c.default || c))
export const LayoutFormRadio = () => import('../../components/layout/Form/Radio.vue' /* webpackChunkName: "components/layout-form-radio" */).then(c => wrapFunctional(c.default || c))
export const LayoutFormRadioGroup = () => import('../../components/layout/Form/RadioGroup.vue' /* webpackChunkName: "components/layout-form-radio-group" */).then(c => wrapFunctional(c.default || c))
export const TemplatesLayoutWidget = () => import('../../components/templates/layout/widget.vue' /* webpackChunkName: "components/templates-layout-widget" */).then(c => wrapFunctional(c.default || c))
export const MoleculesModalsCurrency = () => import('../../components/molecules/modals/Currency.vue' /* webpackChunkName: "components/molecules-modals-currency" */).then(c => wrapFunctional(c.default || c))
export const MoleculesModalsEventSubscribe = () => import('../../components/molecules/modals/EventSubscribe.vue' /* webpackChunkName: "components/molecules-modals-event-subscribe" */).then(c => wrapFunctional(c.default || c))
export const MoleculesModalsTerms = () => import('../../components/molecules/modals/Terms.vue' /* webpackChunkName: "components/molecules-modals-terms" */).then(c => wrapFunctional(c.default || c))
export const TemplatesPagesDescription = () => import('../../components/templates/pages/description.vue' /* webpackChunkName: "components/templates-pages-description" */).then(c => wrapFunctional(c.default || c))
export const TemplatesPagesGroup = () => import('../../components/templates/pages/group.vue' /* webpackChunkName: "components/templates-pages-group" */).then(c => wrapFunctional(c.default || c))
export const TemplatesPagesHome = () => import('../../components/templates/pages/home.vue' /* webpackChunkName: "components/templates-pages-home" */).then(c => wrapFunctional(c.default || c))
export const TemplatesPagesWidget = () => import('../../components/templates/pages/widget.vue' /* webpackChunkName: "components/templates-pages-widget" */).then(c => wrapFunctional(c.default || c))
export const TemplatesSectionsSpotlight = () => import('../../components/templates/sections/Spotlight.vue' /* webpackChunkName: "components/templates-sections-spotlight" */).then(c => wrapFunctional(c.default || c))
export const TemplatesSectionsSpotlightGrid = () => import('../../components/templates/sections/SpotlightGrid.vue' /* webpackChunkName: "components/templates-sections-spotlight-grid" */).then(c => wrapFunctional(c.default || c))
export const TemplatesPagesEventTicketselector = () => import('../../components/templates/pages/event/ticketselector.vue' /* webpackChunkName: "components/templates-pages-event-ticketselector" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
